import '@reach/dialog/styles.css';
import React, { useContext } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import { GlobalContext } from '@context/GlobalContext';
import './VideoOverlayCallToAction.scss';

const VideoOverlayCallToAction: React.FC = () => {
    const [globalState, setGlobalState] = useContext(GlobalContext);

    const className = ['VideoOverlayCallToAction']
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    function handleOpenVideo() {
        setGlobalState((prevData: any) => ({
            ...prevData,
            videoModalIsOpen: !globalState.videoModalIsOpen,
        }));
    }

    return (
        <div className={className}>
            <button
                className="VideoOverlayCallToAction--button"
                onClick={handleOpenVideo}
            >
                <div
                    className="VideoOverlayCallToAction--background"
                    style={{ backgroundImage: 'url(/img/videoThumbnail.gif)' }}
                ></div>

                <div className="VideoOverlayCallToAction--playButton">
                    <AiFillPlayCircle fill="#ffffff" size={'60'} />
                </div>
            </button>
        </div>
    );
};

export default VideoOverlayCallToAction;
