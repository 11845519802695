import { graphql } from 'gatsby';
import Link from '@components/Link';

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import emojiDoctor from '../../../../static/img/emojis/female-health-worker.png';
import emojiMicroscope from '../../../../static/img/emojis/microscope.png';
import emojiCalm from '../../../../static/img/emojis/relieved-face.png';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import Card from '@components/Card/Card';
import CardGallery from '@components/CardGallery/CardGallery';
import CoveragePricingShortInfo from '@components/CoveragePricingShortInfo';
import FatNumber from '@components/FatNumber/FatNumber';
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import Stepper from '@components/Stepper';
import VideoOverlay from '@components/VideoOverlay';
import VideoOverlayCallToAction from '@components/VideoOverlayCallToAction/VideoOverlayCallToAction';
import { withLocale } from '@hocs';
import useLocale from '../../../hooks/useLocale';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "approach/header-why-aepsy-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "approach/header-why-aepsy-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const ApproachPage = ({ data: static_images }) => {
    const { getAppLinkWithCurrentLang } = useLocale();
    const { t } = useTranslation();
    // Header mobile
    const static_images_sources = [
        static_images.headerMobileImage.childImageSharp.fluid,
        {
            ...static_images.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    const { pricingCardImage, pricingCardMobileImage } = static_images
    return (
        <Layout>
            <Seo
                title={t('approach.seotitle')}
                description={t('approach.seodescription')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('approach.hero.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('learn.more')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={static_images_sources}
                    />
                </Section>

                {/* 3 Intro Cards */}
                <div id="mehr">
                    <Section theme="dark" container="large" spacingBottom="xs">
                        <CardGallery size="3" layout="cascade">
                            {/* BiCheckShield */}
                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={
                                    <FatNumber
                                        hero="3"
                                        tagline={t(
                                            'approach.key.usp.one.tagline'
                                        )}
                                    />
                                }
                                title={t('approach.key.usp.one.title')}
                                text={t('approach.key.usp.one.text')}
                            />

                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={
                                    <FatNumber
                                        hero="33%"
                                        tagline={t(
                                            'approach.key.usp.two.tagline'
                                        )}
                                        taglineTop
                                    />
                                }
                                title={t('approach.key.usp.two.title')}
                                text={t('approach.key.usp.two.text')}
                            />
                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={
                                    <FatNumber
                                        hero="+7000"
                                        tagline={t(
                                            'approach.key.usp.three.tagline'
                                        )}
                                        taglineTop
                                    />
                                }
                                title={t('approach.key.usp.three.title')}
                                text={t('approach.key.usp.three.text')}
                            />
                        </CardGallery>
                    </Section>
                </div>

                {/* Header Quote */}
                <Section theme="dark" container="large" spacingBottom="xl">
                    <Title tag="p" size="xl" theme="white">
                        <Trans
                            i18nKey={'approach.intro.statement'}
                            components={{ br: <br /> }}
                        />
                    </Title>
                </Section>
                {/* Breakout banner */}
                <Section container="large">
                    <VideoOverlayCallToAction />
                    <VideoOverlay />
                </Section>

                {/* 3 Step process */}
                <Section container="short" spacingTop="s" theme="light">
                    <Title align="center">
                        {t('approach.usp.explained.section.title')}
                    </Title>

                    <div className="g_1_4 g_center">
                        <Divider spacing="m" />
                    </div>

                    <div className="g_2_3 g_center">
                        <Text align="center">
                            {t('approach.usp.explained.section.subtitle')}
                        </Text>
                    </div>
                </Section>
                <Section theme="light" container="large" spacingTop="l">
                    <CardGallery
                        size="3"
                        layout="cascade"
                        variant="mobileInline"
                    >
                        <Card
                            format="v"
                            variant="heroTitle"
                            visual={<FatNumber emoji={emojiMicroscope} />}
                            title={t('approach.usp.explained.block.one.title')}
                            text={t('approach.usp.explained.block.one.text')}
                            link={
                                <>
                                    <Divider spacing="s" />
                                    <Text size="footnote">
                                        {t(
                                            'approach.usp.explained.block.one.footnote'
                                        )}
                                    </Text>
                                </>
                            }
                        />
                        <Card
                            format="v"
                            variant="heroTitle"
                            visual={<FatNumber emoji={emojiCalm} />}
                            title={t('approach.usp.explained.block.two.title')}
                            text={t('approach.usp.explained.block.two.text')}
                        />
                        <Card
                            format="v"
                            variant="heroTitle"
                            visual={<FatNumber emoji={emojiDoctor} />}
                            title={t(
                                'approach.usp.explained.block.three.title'
                            )}
                            text={t('approach.usp.explained.block.three.text')}
                        />
                    </CardGallery>
                </Section>

                {/* The Aepsy Approach */}

                <Section spacingBottom="l" spacingTop="m">
                    <Section container="short" spacingBottom="m">
                        <TitleGroup
                            title={t('approach.insurance.info.section.title')}
                            subtitle={t(
                                'approach.insurance.info.section.subtitle'
                            )}
                            align="desktopCenter"
                        />
                    </Section>

                    <Stepper
                        list={[
                            {
                                icon: '🤝',
                                title: t(
                                    'approach.insurance.info.how.to.step.one.title'
                                ),
                                subtitle: t(
                                    'approach.insurance.info.how.to.step.one.subtitle'
                                ),
                                buttonLabel: t('button.get.started'),
                                url: getAppLinkWithCurrentLang(process.env.GATSBY_START_FULL_FUNNEL_URL),
                            },
                            {
                                icon: '📞',
                                title: t(
                                    'approach.insurance.info.how.to.step.two.title'
                                ),
                                subtitle: t(
                                    'approach.insurance.info.how.to.step.two.subtitle'
                                ),
                            },
                            {
                                icon: '👩‍⚕️',
                                title: t(
                                    'approach.insurance.info.how.to.step.three.title'
                                ),
                                subtitle: t(
                                    'approach.insurance.info.how.to.step.three.subtitle'
                                ),
                            },
                            {
                                icon: '🗓',
                                title: t(
                                    'approach.insurance.info.how.to.step.four.title'
                                ),
                                subtitle: t(
                                    'approach.insurance.info.how.to.step.four.subtitle'
                                ),
                            },
                        ]}
                    />
                </Section>

                {/* So funktioniert’s section */}
                <Section theme="dark" spacing="m" container="large">
                    <Section container="short" spacingBottom="s">
                        <Title align="center" theme="white">
                            {t('approach.insurance.info.section.title')}
                        </Title>

                        <div className="g_1_4 g_center">
                            <Divider spacing="m" />
                        </div>

                        <div className="g_2_3 g_center">
                            <Text align="center" theme="white">
                                {t('approach.insurance.info.section.subtitle')}
                            </Text>
                        </div>
                    </Section>
                    <CardGallery size="3" variant="mobileInline">
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.one.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.one.text'
                            )}
                        />
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.two.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.two.text'
                            )}
                        />
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.three.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.three.text'
                            )}
                        />
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.four.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.four.text'
                            )}
                        />
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.five.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.five.text'
                            )}
                        />
                        <Card
                            format="h"
                            variant="heroTitle"
                            title={t(
                                'approach.how.does.it.work.feature.six.title'
                            )}
                            text={t(
                                'approach.how.does.it.work.feature.six.text'
                            )}
                        />
                    </CardGallery>
                    <Divider spacing="l" invisible />
                    <div className="ButtonGroupLine g_center">
                        <a href={getAppLinkWithCurrentLang(process.env.GATSBY_START_FULL_FUNNEL_URL)}>
                            <Button
                                align="center"
                                size="l"
                                theme="white"
                                label={t('find.pyschologist.cta.label')}
                            />
                        </a>
                        <Link to="/for-therapists/">
                            <Button
                                align="center"
                                size="l"
                                theme="white"
                                label={t('nav.label.for.psychologist')}
                            />
                        </Link>
                    </div>
                </Section>

                {/* Pricing Section */}
                <Section container="large" spacing="m" theme="light">
                    <CoveragePricingShortInfo
                        imageUrlSharp={pricingCardImage}
                        mobileImageUrlSharp={pricingCardMobileImage}
                    />

                    <Link to="/coverage/">
                        <Button
                            theme="highlighted"
                            label={t('pricing.card.button.to.coverage')}
                            iconArrow
                            align="center"
                        />
                    </Link>
                </Section>

                {/* Footer */}
                <Footer variant="extended" />
            </div>
        </Layout>
    );
};

export default withLocale(ApproachPage);
